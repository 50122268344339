$theme-colors: (
    "primary": #b57f28,
    "dark": #000000
);

@import "node_modules/bootstrap/scss/bootstrap";

body {
    font-family: open-sans, sans-serif;
    font-weight: 300;
}

.navbar {
    border-bottom: 0px solid #eee !important;
    .navbar-brand {
        max-width: 200px;
    }
    .navbar-collapse {
        flex-direction: column;
    }
    .nav-item {
        padding-left: 20px;
        .nav-link {
            text-transform: uppercase;
            padding: 0 0 10px 0 !important;
            border-bottom: 1px solid transparent;
            &:hover {
                border-bottom: 1px solid theme-color("primary");
            }
        }
    }
    .dropdown-menu {
        background: theme-color("primary");
        border-radius: 0;
        border: 0px;
        a {
            color: #fff;
        }
        a:hover{
            background: #000;
            color: #fff;
        }
    }
}
.tel,
.email {
  a {
    font-size: 1rem;
    color: #fff;
    svg {
        margin-right: 10px;
        fill: #fff;
    }
    &.email {
        svg {
            fill: theme-color("primary");
        }
        &:hover {
            color: #fff;
            svg {
                fill: #fff;
            }
        }
    }
    &:hover {
        color: theme-color("primary");
        text-decoration: none;
        svg {
            fill: theme-color("primary");
        }
    }
  }
}
@include media-breakpoint-down(sm) {
  .tel,
  .email {
    display: block;
    clear: both;
    padding-top: 10px;
}
}
.social {
    float: left;
    list-style: none;
    padding: 0;
    margin: 0 0 10px 0;
    li {
        display: block;
        float: left;
        padding-left: 20px;
        svg {
            fill: theme-color("primary");
        }
    }
}

@include media-breakpoint-up(md) {
    .navbar .navbar-collapse {
        flex-direction: column-reverse;
     }
     .social {
        float: right;
        li {
            float: right;
        }
     }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: museo-sans, sans-serif;
    font-weight: 500;
}

#vimeo-background {
  margin-top: 110px;
  position: relative;
  width: 100vw; /* The video will always fit the width of the viewport */
  height: calc(100vw * 9 / 16); /* Calculate height based on a 16:9 aspect ratio */
  overflow: hidden;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

#vimeo-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* The iframe will always fill the container width */
  height: 100%; /* The height will automatically adjust to maintain aspect ratio */
  object-fit: contain; /* Ensure the entire video is visible, maintaining aspect ratio */
  pointer-events: none; /* Prevent interaction with the iframe */
}

#muteToggle {
  position: absolute;
  z-index: 10;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
}

@media (min-width: 768px) {

  #vimeo-background {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: hidden; /* Hide any excess part of the video */
    max-height: calc(100vh - 120px);
  }

  #vimeo-background iframe {
    position: absolute;
    top: 50%; /* Vertically center the video */
    left: 50%; /* Horizontally center the video */
    width: 177.78vh; /* Ensure width fills screen based on a 16:9 ratio */
    height: 100vh; /* Fill the viewport height */
    transform: translate(-50%, -50%); /* Translate by 50% to center the iframe */
    min-width: 100vw; /* Ensure it covers full width */
    min-height: 56.25vw; /* Ensure it covers full height based on 16:9 ratio */
    object-fit: cover; /* Crop excess parts to maintain aspect ratio */
    pointer-events: none; /* Disable interactions with the iframe */
  }

  #muteToggle {
    bottom: 80px;
    right: 80px;
    width: 80px;
    height: 80px;
  }
}


// #vimeo-background {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: calc(50vh - 10px);
//   z-index: -1;
//   overflow: hidden; /* Hide any excess part of the video */
//   margin-top: 50px;
// }

// #vimeo-background iframe {
//   position: absolute;
//   top: 50%; /* Vertically center the video */
//   left: 50%; /* Horizontally center the video */
//   width: 177.78vh; /* Ensure width fills screen based on a 16:9 ratio */
//   height: calc(50vh - 10px);
//   transform: translate(-50%, -50%); /* Translate by 50% to center the iframe */
//   min-width: 100vw; /* Ensure it covers full width */
//   min-height: 56.25vw; /* Ensure it covers full height based on 16:9 ratio */
//   object-fit: cover; /* Crop excess parts to maintain aspect ratio */
//   pointer-events: none; /* Disable interactions with the iframe */
// }

// @media (min-width: 768px) {
//   #vimeo-background {
//     height: 100vh;
//   }

//   #vimeo-background iframe {
//     height: 100vh; /* Fill the viewport height */
//   }
// }


.modalVideo {
  .close {
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 20px;
    span {
      color: #fff;
      font-size: 3rem;
    }
  }
}

.hero {
    height: calc(50vh - 10px);
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
    text-align: center;
    position: relative;
    overflow: hidden;
    .holder {
        max-width: 900px;
        padding: 20px 20px;
    }
    h1,
    .h1 {
      font-size: 3rem;
      line-height: 4.6rem;
      position: relative;

      span {
        display: inline;
        color: #fff;
        padding-left: 0;
        padding-right: 0;
        -webkit-box-decoration-break: clone;
        -ms-box-decoration-break: clone;
        -o-box-decoration-break: clone;
        box-decoration-break: clone;
        background: #000;
        padding: 0.2rem 1rem;
        box-shadow: 0.2rem 0 0 #000, -0.2rem 0 0 #000;
      }
    }
}

@include media-breakpoint-up(md) {
  .hero {
    height: calc(100vh - 10px);
    padding: 80px 20px;

    &.hero-sml {
      height: 600px; 
    }

    h1,
    .h1 {
      font-size: 4rem;
      line-height: 6.25rem;
    }
  }
}

.hero-video {
  position: relative;
  background-size: cover;
  background-position: center;
}
.stripes {
    div {
        height: 10px;
        width: 25%;
        float: left;
        display: block;
        &.corporate {
            background: #498faf;
        }
        &.public {
            background: #744089;
        }
        &.international {
            background: #bf3347;
        }
        &.musical {
            background: #499268;
        }
    }
}
.bg-primary {
    background: theme-color("primary");
    color: #fff;
}
.bg-black {
    background: #000;
    color: #fff;
}

.bg-white {
    background: #fff;
    color: #000;
}

.bg-grey {
    background: #f3f3f3;
}
.btn {
    border-radius: 0px;
    text-transform: uppercase;
}

.btn-outline-primary {
  color: #000;
}

.packages {
  font-size: 1.4rem;
  padding: 20px 0;
}

.heading {
    h4 {
        text-transform: uppercase;
        padding-bottom: 10px;
        margin: 0;
        border-bottom: 8px solid theme-color("primary");
        display: inline-block;
        font-size: 2rem;
    }
    &.bg-primary h4{
        border-color: #000;
    }
}

.weddings-heading {
    text-transform: uppercase;
    padding-bottom: 10px;
    margin: 0;
    border-bottom: 8px solid theme-color("primary");
    display: inline-block;
    font-size: 2rem;
    position: absolute;
    top: -104px;
    color: #fff;
}

.display-4 {
    font-size: 2.6rem;
    font-family: museo-sans, sans-serif;
    font-weight: 100;
}

.section-heading {
    margin: 30px 0;
    color: theme-color("primary");
    text-transform: uppercase;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 1px;
        background: theme-color("primary");
        display: block;
        position: absolute;
        left: 0;
        top: 15px;
        z-index: 1;
    }
    span {
        background: #000;
        position: relative;
        z-index: 2;
        padding: 0 20px;
    }
}

.video {
    text-align: center;
    img{
        border: 1px solid #fff;
        opacity: 1;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }
    h4 {
        font-size: 1.2rem;
        margin-top: 20px;
        font-weight: 300;
        text-transform: uppercase;
    }
    &:hover {
        text-decoration: none;
        img {
            opacity: 0.6;
        }
    }
}
span {
    color: theme-color("primary");
}

.footer {
    border-top: 1px solid theme-color("primary");
    background: #000;
    color: #fff;
    .nav-link {
        padding: 5px 0 5px 0;
        text-transform: uppercase;
        font-size: 0.9rem;
        letter-spacing: 0.1rem;
    }
    a {
        color: #fff;
    }
}

.overview {
    text-align: center;
    min-height: 5px;
    width: 100%;
    display: block;

    h4 {
        background:$primary;
        padding: 15px;
        color: #fff;
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: 300;
        background: transparent;
    }
    img {
        opacity: 1;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }
    &.corporate {
        background: #498faf;
    }
    &.public {
        background: #744089;
    }
    &.international {
        background: #bf3347;
    }
    &.musical {
        background: #499268;
    }
    &:hover {
        text-decoration: none;
        img {
            opacity: 0.6;
        }
    }
}

#lightgallery,
#videogallery {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 2px;
	margin: 0;
	a {
		width: 50%;
        padding: 2px;
        img{
            width: 100%;
            height: auto;
        }
        &:hover {
            img {
                opacity: 0.5;
            }
        }
	}
}

@include media-breakpoint-up(md) {
  #lightgallery a,
  #videogallery a {
    width: 25%;
  }
}

.bullets {
    list-style: none;
    padding: 0;
    li {
        position: relative;
        padding-left: 40px;
        font-size: 1.2rem;
        padding-bottom: 10px;
        &::before {
            content: '';
            width: 26px;
            height: 26px;
            position: absolute;
            left: 0;
            top: 0;
            background-image: url(/img/star.svg);
            background-size: cover;
        }
    }
}

.padding-default {
    padding-top: 80px;
    padding-bottom: 80px;
}
.padding-top {
    padding-top: 80px;
}
.padding-bottom {
    padding-bottom: 80px;
}

$grid-gutter-small: 5px;
$grid-gutter-large: 50px;

.row-no-gutter {
  margin: 0;
}

.row-no-gutter > * {
  padding: 0;
}

.row-small-gutter {
  margin-left: -$grid-gutter-small;
  margin-right: -$grid-gutter-small;
}

.row-small-gutter > * {
  padding-left: $grid-gutter-small;
  padding-right: $grid-gutter-small;
}

.row-large-gutter {
  margin-left: -$grid-gutter-large;
  margin-right: -$grid-gutter-large;
}

.row-large-gutter > * {
  padding-left: $grid-gutter-large;
  padding-right: $grid-gutter-large;
}
#enquiryForm {
  input,
  textarea {
    margin-bottom: 20px;
    background: rgba(#fff, 0.3);
    border-radius: 0;
    border: 0;
    color: #fff;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #eee;
      opacity: 1; /* Firefox */
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #eee;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #eee;
    }
  }
}